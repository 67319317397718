import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "action"
    }}>{`Action`}</h1>
    <p>{`Sigi 的 `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 底层由一个名为 `}<inlineCode parentName="p">{`State`}</inlineCode>{` 的对象实现。`}<inlineCode parentName="p">{`State`}</inlineCode>{` 对象和 `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` 的 `}<inlineCode parentName="p">{`Store`}</inlineCode>{` 非常类似。它总是接收从 `}<inlineCode parentName="p">{`Dispatcher`}</inlineCode>{` 和 `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` 发送过来的 `}<inlineCode parentName="p">{`Action`}</inlineCode>{`, 并且将它们传递给 `}<inlineCode parentName="p">{`Reducer`}</inlineCode>{` 和 `}<inlineCode parentName="p">{`Effect`}</inlineCode>{`。`}</p>
    <p>{`在使用 Sigi 的应用中，你不需要手动创建 `}<inlineCode parentName="p">{`Action`}</inlineCode>{`，所以我们并没有对外提供 `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` 方法。取而代之有两种方法可以达到 `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` action 的效果:`}</p>
    <ul>
      <li parentName="ul">{`调用 `}<strong parentName="li">{`Dispatcher`}</strong>{` 上的方法`}</li>
      <li parentName="ul">{`在 `}<strong parentName="li">{`Effect`}</strong>{` 中发射 (emit) action`}</li>
    </ul>
    <h2 {...{
      "id": "dispatcher--actionscreator"
    }}>{`Dispatcher & ActionsCreator`}</h2>
    <p><strong parentName="p">{`Dispatcher`}</strong>{` 是一个包含丰富类型定义的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 的抽象。
所有 `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 类上被 `}<strong parentName="p">{`Reducer`}</strong>{`, `}<strong parentName="p">{`ImmerReducer`}</strong>{`, `}<strong parentName="p">{`Effect`}</strong>{` 和 `}<strong parentName="p">{`DefineAction`}</strong>{` 装饰器装饰的方法，都在 `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 类中的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 对象上有一个对应的方法，也在 `}<inlineCode parentName="p">{`Component`}</inlineCode>{` 中的 `}<strong parentName="p">{`Dispatcher`}</strong>{` 上有一个对应的方法。`}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` 类中的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 用来生成一个 `}<inlineCode parentName="p">{`Action`}</inlineCode>{` 并且发射(emit) 给 `}<inlineCode parentName="p">{`State`}</inlineCode>{` 对象，`}<inlineCode parentName="p">{`Component`}</inlineCode>{` 中的 `}<strong parentName="p">{`Dispatcher`}</strong>{` 用来直接 `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` 一个 Action 给 `}<inlineCode parentName="p">{`State`}</inlineCode>{` 对象。`}</p>
    <h3 {...{
      "id": "dispatch-props-created-by-redux-actions"
    }}>{`Dispatch props created by Redux-Actions`}</h3>
    <p>{`你可能在写 `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` 的时候用过 `}<a parentName="p" {...{
        "href": "https://github.com/redux-utilities/redux-actions"
      }}>{`Redux-Actions`}</a>{` 这个库。`}<inlineCode parentName="p">{`Sigi`}</inlineCode>{` 中的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 概念与 `}<inlineCode parentName="p">{`Redux-Actions`}</inlineCode>{` 库中的 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 非常类似。在 `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` 应用中 `}<strong parentName="p">{`ActionsCreator`}</strong>{` 有几个明显的好处:`}</p>
    <ul>
      <li parentName="ul">{`避免在程序中编写 `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Magic_number_(programming)"
        }}>{`魔法字符串`}</a></li>
      <li parentName="ul">{`(在 TypeScript 应用中)提供类型信息`}</li>
      <li parentName="ul">{`减少样板代码`}</li>
    </ul>
    <p>{`下面的例子展示了在 `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` 应用中使用和不使用 `}<inlineCode parentName="p">{`Redux-Actions`}</inlineCode>{` 的区别:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// raw dispatcher
connect(mapStateToProps, (dispatch) => bindActionCreators({
  addCount: (count: number) => dispatch({ // losing type information here
    type: 'ADD_COUNT',
    payload: count,
  }),
}, dispatch))

// reducer
export const reducer = (state, action) => { // losing type information here
  switch action.type:
    case: 'ADD_COUNT':
      return { ...state, count: state.count + payload }
    default:
      return { count: 0 }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const ADD_COUNT = createAction<number>("ADD_COUNT");

interface DispatchProps {
  addOne: typeof ADD_COUNT;
}

interface StateProps {
  count: number;
}

// react actions dispatcher
connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      addCount: ADD_COUNT,
    } as DispatchProps,
    dispatch
  )
);

// reducer
export const reducer = handleActions(
  {
    [\`\${ADD_COUNT}\`]: (state: StateProps, { payload }: Action<number>) => {
      return { ...state, count: state.count + payload };
    },
  },
  { count: 0 }
);
`}</code></pre>
    <h3 {...{
      "id": "使用-sigi-管理-actions"
    }}>{`使用 Sigi 管理 actions`}</h3>
    <h4 {...{
      "id": "定义-effectmodule"
    }}>{`定义 EffectModule`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Module, EffectModule, Reducer } from "@sigi/core";
interface State {
  count: number;
}

@Module("SomeNamespace")
class SomeModule extends EffectModule<State> {
  defaultState = {
    count: 0,
  };

  @Reducer()
  add(state: State, count: number) {
    return { ...state, count: state.count + count };
  }
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`可以从上面的代码看到，没有任何魔法字符串，也没有冗余的样板代码 🤯。`}</p>
    </blockquote>
    <h4 {...{
      "id": "使用"
    }}>{`使用`}</h4>
    <p>{`对使用 React 开发的应用，我们提供了两个 hooks 来在组件中引用 effect module 里定义的操作。
这两个 hooks 都接收 EffectModule 类型作为参数，并返回 Module 对于的 dispatcher`}</p>
    <h4 {...{
      "id": "usemodule-和-usedispatchers"
    }}><inlineCode parentName="h4">{`useModule`}</inlineCode>{` 和 `}<inlineCode parentName="h4">{`useDispatchers`}</inlineCode></h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`// 使用 module
import { useDispatchers, useModule } from "@sigi/react";

const SomeComponent = () => {
  // 注意：我们不需要担心 dispatcher 的类型。
  // Sigi 的类型系统可以保证所有定义在 module 中的 reducers/effects 的类型都能 100% 正确地被推导出来。
  const [state, dispatcher] = useModule(SomeModule);
  // 如果某个组件不关心 module 的状态变更（即便状态更新了，也不需要重新渲染），可以这么使用：
  // const dispatcher = useDispatchers(SomeModule);

  const onClickAdd = useCallback(() => {
    // ts 会对 payload 进行类型检查
    // 也可以直接在这里使用**跳转到定义**类似的编辑器功能！
    dispatcher.add(1);
    // 下面这行会出现 ts 类型错误
    // dispatch.add('string value');
  }, [dispatcher]);

  return (
    <div>
      <button onClick={onClickAdd}>add 1</button>
      <span>count: {state.count}</span>
    </div>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      